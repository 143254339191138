#App
{
  background-color: #add8e6;
}
#content
{
  padding-bottom: 100px;
  width: 60%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}
.column
{
  margin-left: 5px;
  margin-right: 5px;
  width: 50%;
  text-align: justify;
}
#header
{
  text-align: center;
  width: 100%;
  margin: auto;
  font-size: 50px;
}
#wrapper
{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
#loading
{
  font-size: 50px;
}
#footer
{ 
  padding-top: 70px;
  display: flex;
  min-height: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2e4249;
  color: white;
  position: relative;
  text-align: center;
  font-size: 10px;
}
#footer a
{
  text-decoration: none;
  color: #add8e6;
}
.custom-shape-divider-top-1644602852 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1644602852 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 82px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1644602852 .shape-fill {
  fill: #ADD8E6;
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
  .custom-shape-divider-top-1644602852 svg {
      width: calc(110% + 1.3px);
      height: 69px;
  }
}

/** For mobile devices **/
@media (max-width: 767px) {
  .custom-shape-divider-top-1644602852 svg {
      width: calc(137% + 1.3px);
      height: 44px;
  }
}